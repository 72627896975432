import * as React from "react"
import PropTypes from "prop-types"

import { useLocation } from '@reach/router';

import Header from "./header"
import SubscribeForm from "./subscribe-form"
import Footer from "./footer"

import "./layout.css"
import "../css/custom.css"

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <>
        <main>
          <Header></Header>
          {children}
          {location.pathname !== '/unsubscribe' && <SubscribeForm/>}
          <Footer></Footer>
        </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
