import * as React from "react"
import PropTypes from "prop-types"

import { StaticImage } from "gatsby-plugin-image"
import socialIcons from './constants/social'
import "../css/header.css"
import { Link } from "gatsby"

const Header = () => (
  <header
    style={{
      display: `flex`,
      height: `14rem`
    }}
  >

  <div className="logoParent" style={{flex: `1`, display: `flex`, alignItems: `center` }}>
    <Link to="/">
      <StaticImage
        src="../images/RobustGames_FullLockup_RGB_HighDetail_Negative.png"
        alt="Robust Games logo - a cute robot on an orange background."
        placeholder="blurred"
        layout="fixed"
        width={128}
        formats={["AUTO", "WEBP", "AVIF"]}
      />
    </Link>
  </div>

  <div className="iconParent">
    <div className="headerIcons">
      <a href="https://locomotivegame.com/">
        <StaticImage
          src="../images/locoicon.png"
          alt="Robust Games logo - a cute robot on an orange background."
          width={22}
          formats={["AUTO", "WEBP", "AVIF"]}
          imgClassName="custom-icon"
          placeholder="tracedSVG"
        />
      </a>
      {socialIcons.map((item, index)=>{return <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">{item.icon}</a>})}
    </div>
  </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
