import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import socialIcons from './constants/social'
import "../css/footer.css"
import { Link } from 'gatsby'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="icons">
                <a href="https://locomotivegame.com/">
                    <StaticImage
                    src="../images/locoicon.png"
                    alt="Robust Games logo - a cute robot on an orange background."
                    width={16}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    imgClassName="custom-icon"
                    placeholder="tracedSVG"
                    />
                </a>
                {socialIcons.map((item, index)=>{return <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">{item.icon}</a>})}
            </div>
            <div className="copyright">&copy; {new Date().getFullYear()} Robust Games | <Link to="/privacy" className="link">Privacy Policy</Link></div>
        </footer>
    )
}

export default Footer