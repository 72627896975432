import React from 'react'
import { IoIosMail, IoLogoInstagram, IoLogoTwitter } from "react-icons/io";

const socials = [
    {
        icon: <IoLogoInstagram/>,
        url: 'https://www.instagram.com/robustgames/'
    },
    {
        icon: <IoLogoTwitter/>,
        url: 'https://twitter.com/RobustGames'
    },
    {
        icon: <IoIosMail/>,
        url: 'mailto:hello@robustgames.org'
    }
];
export default socials;