import React from 'react';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'gatsby'

export default class SubscribeForm extends React.Component {
    state = {
        name: null,
        email: null,
    }

    _handleChange = e => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault();
        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                if (result !== 'success') { throw msg }
                toast.success(msg, {theme: "colored"});
            })
            .catch(err => {
                // Original error message was stupidly long and includes a user specific hyperlink that doesn't render properly
                if(err.includes("is already subscribed to list"))
                    toast.error(this.state.email + " is already subscribed to our mailing list.", {theme: "colored"});
                else
                    toast.error(err, {theme: "colored"});
            })
    }

    render() {
        return (
            <div className="flex-container" style={{margin: `auto`, marginBottom: `8px`, textAlign: `center`, justifyContent: `end`}}>
                <div style={{textAlign: `center`}}>
                    <div style={{padding: `1em`}}>
                    <h1 style={{color: `var(--primaryColor)`}}>Newsletter</h1>
                    <p style={{color: `white`}}>Sign up to hear the latest from Robust Games!</p>
                    </div>
                    
                    <div>
                        <form onSubmit={this._handleSubmit}>
                            <input
                                type="email"
                                onChange={this._handleChange}
                                placeholder="example@email.com"
                                name="email"
                            />
                            <button type="submit">
                                <FaArrowRight/>
                            </button>
                        </form>
                    </div>
                    <div style={{padding: `1em`}}>
                    <p style={{color: `white`, fontSize: `14px`}}>You can <a href="https://games.us5.list-manage.com/unsubscribe?u=8b0affa2b5896e95782cc8858&id=4afe56e1bc" className="inline-link" target="_blank" rel="noopener noreferrer">unsubscribe</a> anytime. More information about how we look after your data is in our <Link to="/privacy" className="inline-link">Privacy Policy</Link>.</p>
                    </div>

                </div>
                <ToastContainer
                    transition={Flip}
                    position="top-center"
                    autoClose={5000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                />
            </div>
        )
    }
}